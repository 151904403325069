<!--  -->
<template>
  <div>
    <div class="content">
      <!-- 头部 -->
      <div class="">
        <div class="me_title">
          <!-- 左边 -->
          <div class="me_title_left">
            <div class="img" style="cursor: pointer" @click="$handleRoute('/me')">
              <!-- <el-avatar
                  :size="75"
                  icon="el-icon-user-solid"
                  :src="infoData.headimg||require('@/assets/images/index/tx.png')"

              ></el-avatar> -->

              <el-image style="width: 75px; height: 75px;border-radius: 50%;" :src="userInfoData.headimg||require('@/assets/index/tx.png')"  fit="cover"></el-image>
              <!-- <el-image style="width: 75px; height: 75px;border-radius: 50%;" :src="userInfo.headimg"  fit="cover"></el-image> -->
              
            </div>
            <div class="info">
              <p class="name" style="cursor: pointer" @click="$handleRoute('/me')">
                {{ userInfoData.nickname ? userInfoData.nickname : userInfoData.phone }}
              </p>
              <!-- <p class="name" style="cursor: pointer" @click="$handleRoute('/me')">
                {{ userInfo.nickname ? userInfo.nickname : userInfo.phone }}
              </p> -->
              <!-- <p class="top">全站排名></p> -->
            </div>
          </div>
          <!-- 右边 -->
          <div class="me_title_right" v-if="infoData.id">
          <!-- <div class="me_title_right" > -->
            <div v-if="userInfoData.id+'' === userInfo.id+''">
              <el-badge style="margin-right: 20px;" :value="0" class="item" hidden  v-if="($route.matched[routerViewDepth+1]||currentRoute).name.indexOf('meFeedback') === 0">
                <div v-href="'meFeedback'" style="cursor: default;" :class="{btn:true,active:true}">我的反馈</div>
              </el-badge>
              <!-- <el-badge style="margin-right: 20px;" :value="infoData.bill_notread" class="item" v-if="userType === 2" :hidden="!infoData.bill_notread">
                <div v-href="'meBill'" :class="{btn:true,active:($route.matched[routerViewDepth+1]||currentRoute).name==='meBill'}" @click="$handleRoute('meBill')">我的账单</div>
              </el-badge>
              <el-badge :value="infoData.msg_notread" class="item" :hidden="!infoData.msg_notread">
                <div v-href="'meMessage'" :class="{btn:true,active:($route.matched[routerViewDepth+1]||currentRoute).name==='meMessage'}" @click="$handleRoute('meMessage')">我的消息</div>
              </el-badge> -->
            </div>
            <div v-else :class="{btn:true}" @click="$routerGo(-1,{name:'me'})">返回</div>
          </div>

        </div>
      </div>

      <!-- 内容 -->
      <div class="me_content">
        <!-- 左边 -->
        <div class="me_content_left">
          <!-- 个人资料 -->
          <div class="info" v-href="'meInfoPersonalData'" @click="$handleRoute('meInfoPersonalData')" v-if="!student_user_id&&userType===1">编辑个人资料</div>
          <div class="info" v-href="'meInfoEditTeach'" @click="$handleRoute('meInfoEditTeach')" v-if="!student_user_id&&userType===2">编辑教师资料</div>
          <template>
            <!-- 成就贡献 -->
            <div class="achievement">
              <div class="title">成就贡献</div>
              <ul>
                <li v-for="(item, index) in achievementListL" :key="index">
                  <div class="img">
                    <img :src="item.img" alt="" />
                    
                  </div>
                  <div class="right">
                    <div class="title">{{ item.title }}</div>
                    <div class="unmText_number">
                      <span class="unmText">{{ item.unmText }}</span><span class="number">{{ userInfoData[item.key] || 0 }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 个人简介 -->
            <div class="introduction">
              <div class="title">个人简介</div>
              <div class="text">
                <p v-if="!userInfoData.description ">暂无个人简介</p>
                <p v-html="userInfoData.description " v-if="userInfoData.description "></p>
              </div>
            </div>
            <!-- 擅长技能 -->
            <div class="skill">
              <div class="title">擅长技能</div>
              <p v-if="!(userInfoData.professional_skill || []).length" style="color:#7d7d7d;font-size: 14px;margin-top:10px">暂无擅长技能</p>
              <ul>
                <li v-for="(item, index) in userInfoData.professional_skill" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </template>
        </div>

        <!-- 右边 -->
        <div class="me_content_right">
          <router-view v-bind:userData.sync="infoData"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userInfo, userInfo2} from "@/api/me.js"
import {getRouterHistorys} from "@/router";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    student_user_id:{
      default: () => 0,
    }
  },
  data () {
    //这里存放数据
    return {
      infoData: {},
      userInfoData: {},
      loading:false,
      otherUserId:0,
      // 成就贡献列表
      achievementListL: [
        {
          img: require("../../assets/images/me/预览@3x.png"),
          title: "阅读总数",
          unmText: "昨日+",
          key: 'z_views_count',
        },
        {
          img: require("../../assets/images/me/点赞@3x.png"),
          title: "获赞总数",
          unmText: "昨日+",
          key: 'z_praise_count',
        },
        {
          img: require("../../assets/images/me/收藏@3x.png"),
          title: "获得收藏",
          unmText: "昨日+",
          key: 'z_collects_count',
        },
      ],
      backRoute:null,
    }
  },
  created(){
    // console.log(this.student_user_id,"student_user_id")
    // console.log(this.$route.params.student_user_id,"=======================")
    // this.otherUserId=this.$route.params.student_user_id
    this.getInfo()
  },
  //方法集合
  methods: {
    userInfoListener(){
      this.infoData = {};
      this.routeUpdate();
    },
    getInfo(){
    console.log(this.student_user_id,"student_user_id11111111111")
      if(this.student_user_id){
    console.log(this.student_user_id,"student_user_id222222222222")

        // 获取个人头像
        userInfo({ student_user_id: this.student_user_id }).then((res) => {
          // var infoData = {...res.data.info}
         this.userInfoData = {...res.data.info}
          // 获取其他信息
          userInfo2({ student_user_id: this.student_user_id }).then((res) => {
            // this.infoData = {...infoData , ...res.data}
            this.infoData = {...this.userInfoData , ...res.data}
            console.log(this.infoData,"获取的用户信息~~~~~~~~")
          })
        })
      }else{
        console.log("自己")
        this.userInfoData=this.userInfo
        userInfo2({ student_user_id: this.userInfo.id }).then((res) => {
          this.infoData = {...this.userInfo , ...res.data}
        }).catch(()=>{this.infoData = {...this.userInfo}})
      }
      // return
      // if(this.otherUserId && this.otherUserId != this.userInfo.id){
      //   userInfo({ student_user_id: this.otherUserId }).then((res) => {
      //     var infoData = {...res.data.info}
      //     userInfo2({ student_user_id: this.otherUserId }).then((res) => {
      //       this.infoData = {...infoData , ...res.data}
      // this.loading=false

      //     console.log( this.infoData," this. this. this.")

      //     }).catch(()=>{this.infoData = infoData;})
      //   }).catch()
      // }else if(this.userInfo){
      //   userInfo2({ student_user_id: this.userInfo.id }).then((res) => {
      //     this.infoData = {...this.userInfo , ...res.data}
      //   }).catch(()=>{this.infoData = {...this.userInfo}})
      // }
    },
    routeUpdate(){
      if(this.backRoute === null && getRouterHistorys().length > 1){
        this.backRoute = {...getRouterHistorys()[1]}
      }
      // var student_user_id = null
      // // if(this.student_user_id && this.student_user_id+'' !== this.userInfo.id+''){
      // if(this.otherUserId && this.otherUserId != this.userInfo.id){
      //   if(!['meStudyCourse','meStudyQuestList','meStudyQuestion' , 'meStudyQuestother'].includes(this.$route.name)){
      //     return this.$handleRoute('meStudyCourse')
      //   }
      //   // student_user_id = this.student_user_id;
      //   // otherUserId = this.otherUserId;
      //   // userInfo({ student_user_id: this.student_user_id }).then((res) => {
      //   userInfo({ student_user_id: this.otherUserId }).then((res) => {
      //     var infoData = {...res.data.info}
      //     // userInfo2({ student_user_id: student_user_id }).then((res) => {
      //     userInfo2({ student_user_id: this.otherUserId }).then((res) => {
      //       this.infoData = {...infoData , ...res.data}
      //     console.log( this.infoData," this. this. this.")

      //     }).catch(()=>{this.infoData = infoData;})
      //   }).catch()
      // }else if(this.userInfo){
      //   // userInfo2({ student_user_id: student_user_id }).then((res) => {
      //   userInfo2({ student_user_id: this.userInfo.id }).then((res) => {
      //     this.infoData = {...this.userInfo , ...res.data}
      //   }).catch(()=>{this.infoData = {...this.userInfo}})
      // }
    },
  },
};
</script>
<style  lang='less' scoped>
 li {
    list-style-type: none;
  } 
.me_content {
  .nothing {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999;
    text-align: center;
    margin-top: 50%;
  }
  display: flex;
  .me_content_left {
	flex: 1;
    width: 20%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 1.5rem 2rem 0;

    .info {
      width: 100%;
      height: 37px;
      line-height: 37px;
      text-align: center;
      background: #f2f2f2;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #191919;
      cursor: pointer;
    }
    .achievement,
    .introduction,
    .skill {
      margin-top: 1.2rem;
      border-bottom: 1px solid #979797;
      padding-bottom: 1.6rem;
      .title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191919;
      }
    }
    .achievement {
      ul {
        li {
          display: flex;
          //   align-items: center;
          margin-top: 2rem;
          .title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #191919;
          }
          .right {
            margin-left: 0.8rem;
          }
          .unmText_number {
            text-align: center;
            height: 16px;
            line-height: 14px;
          }
          .unmText,
          .number {
            font-size: 12px;
            font-weight: 600;
            color: #b4b4b4;
          }
          .img {
            width: 24px;
            height: 24px;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .introduction {
      .text {
        p {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #7d7d7d;
          padding: 1.4rem 0 2rem;
        }
      }
    }
    .skill {
      border-bottom: 0;
      ul {
        padding: 1.1rem 0;
        display: flex;
        flex-wrap: wrap;
        li {
          height: 30px;
          line-height: 30px;
          text-align: center;
          padding: 0 15px;
          background: #00957e;
          border-radius: 2px;
          margin: 0 5px 10px 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .me_content_right {
    width: 80%;
    // height: 200px;
    margin-left: 1.65rem;
  }
}
.me_title_right {
  .el-badge__content.is-fixed {
    right: 15px;
  }
}
.me_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.8rem;
  .me_title_left {
    // cursor: pointer;
    height: 4.8rem;
    display: flex;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 1.2rem;
      .name {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191919;
      }
      .top {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191919;
      }
    }
  }
  .me_title_right {
    display: flex;
    //flex-direction: column-reverse;
    margin-top: 3rem;
    cursor: pointer;
    .btn {
      width: 96px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 2px 14px 0px rgba(164, 164, 164, 0.5);
      border-radius: 6px;
    }
    .active {
      color: #fff;
      background: #00957e;
    }
  }
}
.study_rigth {
  .el-progress-bar__inner {
    line-height: 0;
  }
}
.header {
  .el-row {
    margin: 0;
  }
}
</style>